import React from 'react';

const PrivacyPage = () => {
    return (
        <div>
           <div className="container">
        <div className="policy--service--container">
          <div className="policy--srvice--heading">
            <h3>Privacy policy</h3>
            <div className="policy--service--date">LAST UPDATED - 2021-07-31</div>
          </div>
          <div className="policy--service--content">
            <h4>1.0 - Introduction</h4>
            <p>
            cashbaunty.com have designed this site in compliance with data
              protection regulations to assure you, the user, can use our
              services knowing the integrity of your personal information is
              secure. This privacy policy describes the steps we take to
              maintain the integrity of your information and disclose both how
              and why we collect your information. By continuing to use our
              services, you are consenting to the collection and storage of your
              data as described in this privacy policy.
            </p>
            <h4>2.0 - Data collection</h4>
            <p>
              When using our services, you may be required to share personal
              information about yourself, such as your email address, full name,
              home address etc. This information is collected for the sole
              purpose of providing you with suitable surveys and offers. A
              particular survey may have a determined audience required, so this
              information helps filter out irrelevant surveys which you would
              not have qualified for. We will never knowingly collect personal
              information from anyone under 13 years of age. We also collect
              non-personal information, including your IP address, browser type
              and cookies. This information allows us to provide you with a
              better experience and allows us to minimise fraud on our services.
            </p>
            <h4>3.0 - Cookies and Local Storage</h4>
            <p>
              This site uses Cookies to identify your computer and allow us to
              associate requests from your computer with the relevant user.
              Local Storage provides useful functionality, such as remembering
              user preferences from previous browsing sessions. By using our
              services, you are agreeing with our use of Cookies and Local
              Storage outlined above. Cookies and Local Storage may be cleared
              or blocked at any time, however the functionality of our services
              will be severely limited if you choose to block them.
            </p>
            <h4>4.0 - Third Parties</h4>
            <p>
              The Site uses third-party partners such as offer walls and survey
              routers to provide you as a user with offers and surveys. The
              information that we share with these partners varies depending on
              the offer type. For regular ‘offers’, the only information we
              share is your unique ID generated by us to uniquely identify your
              account. For ‘surveys’, the only information we share is your
              unique ID, along with any information you have provided to us,
              which is passed to help the provider filter out irrelevant
              surveys. Any third-party services integrated in our site may
              collect information from you on their own website as well, and
              they have separate and independent privacy policies. We bear no
              responsibility or liability for their content and activities. If
              you are dissatisfied or concerned about any of these third-party
              privacy policies, please contact us and we will be happy to
              investigate.
            </p>
            <h4>5.0 - Sharing of Information</h4>
            <p>
              Our policy is to not to share, sell, or rent your personal
              information to third parties for our own personal benefit, however
              there may be situations where we are required to share your
              personal details with third parties, such as relevant law
              enforcement agencies. If we believe that it is reasonably
              necessary to comply with law enforcement or other regulatory
              bodies, we may do so without your prior consent or notice.
            </p>
            <p>
              We may also share your personal information if we believe it is
              necessary to investigate, prevent, or act regarding situations
              that pose a threat to the security, rights and assets of the
              company, or violations to our policies or terms of service. We may
              also share your personal information if necessary in cases of
              fraud, such as credit card fraud on offers.
            </p>
            <p>
              The disclosure of sensitive information to relevant organisations
              or authorities is up to the sole discretion of the management of
              the company.
            </p>
          </div>
        </div>
      </div>
        </div>
    );
}

export default PrivacyPage;
