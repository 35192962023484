import React, { useEffect, useState } from "react";
import { checkIp, getEarn } from "../network/earnNet";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { newBalanceAction, setLogout } from "../redux/actions/userActions";
import OfferApi from "../components/OfferApi";
import OfferWall from "../components/OfferWall";
import {
  customStylesIFrame,
  customStylesIFrameApi,
} from "../functions.js/vars";
import { useNavigate } from "react-router-dom";
import { useModal } from "../hooks/useModal";
import { useModalApi } from "../hooks/useModalApi";
import { financialFloat } from "../functions.js/functions";
import { useModalOgads } from "../hooks/useModalOgads";
import CardOfferOgads from "../components/CardOfferOgads";
import { useModalYoumi } from "../hooks/useModalYoumi";
import { useModalHang } from "../hooks/useModalHang";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement("#yourAppElement");

function EarnPage() {
  const { modalIsOpen, closeModal, setIsOpen } = useModal();
  const { modalIsOpenOgads, closeModalOgads, setIsOpenOgads } = useModalOgads();
  const { modalIsOpenYoumi, closeModalYoumi, setIsOpenYoumi } = useModalYoumi();
  const { modalIsOpenHang, closeModalHang, setIsOpenHang } = useModalHang();
  const { modalIsOpenApi, closeModalApi, setIsOpenApi } = useModalApi();
  const [offerwalls, setOfferwalls] = useState([]);
  const [offerApi, setOfferApi] = useState([]);
  // const [adgemApi, setAdgemApi] = useState([]);
  const [ogadsApi, setOgadsApi] = useState([]);
  const [youmi, setYoumi] = useState([]);
  const [hang, setHang] = useState([]);
  const [run_ogads, setRunOgads] = useState(true);
  const [run_youmi, setRunYoumi] = useState(true);
  const [run_hang, setRunHang] = useState(true);
  const [img_youmi, setImgYoumi] = useState(true);
  const [img_hang, setImgHang] = useState(true);
  
  const [selectedOffer, setSelectedOffer] = useState([]);
  const [selectedOfferApi, setSelectedOfferApi] = useState([]);
  const userId = useSelector((state) => state.userReducer.userId);
  const email = useSelector((state) => state.userReducer.email);
  const name = useSelector((state) => state.userReducer.name);
  const balance = useSelector((state) => state.userReducer.balance);
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function showFrame(item) {
    if (!userId) {
      navigate("/login");
    }
    setIsOpen(true);
    try {
      item.data = item.data.replace("[app_uid]", userId);
      item.data = item.data.replace("[user_name]", name);
      item.data = item.data.replace("[user_email]", email);
    } catch (error) {
      // console.log(error);
    }

    setSelectedOffer(item);
  }
  function showFrameOgads() {
    if (!userId) {
      navigate("/login");
    }
    setIsOpenOgads(true);
  }

  function showFrameYoumi() {
    if (!userId) {
      navigate("/login");
    }
    setIsOpenYoumi(true);
  }
  function showFrameHang() {
    if (!userId) {
      navigate("/login");
    }
    setIsOpenHang(true);
  }
  function showModalApi(item) {
    if (!userId) {
      navigate("/login");
    }
    setIsOpenApi(true);
    try {
      item.offer_url = item.offer_url.replace("[USER_ID]", userId);
      // console.log(item.offer_url);
    } catch (error) {
      // console.log(error);
    }

    setSelectedOfferApi(item);
  }
  function showModalAdgemApi(item) {
    if (!userId) {
      navigate("/login");
    }
    setIsOpenApi(true);
    try {
      item.tracking_url = item.tracking_url.replace("{playerid}", userId);
    } catch (error) {
      // console.log(error);
    }
    const newItem = {
      amount: item.amount,
      image_url: item.icon,
      offer_name: item.name,
      payout: item.payout_usd / 3,
      offer_url: item.tracking_url,
      disclaimer: item.description,
      call_to_action: item.instructions,
    };

    setSelectedOfferApi(newItem);
  }

  useEffect(() => {
    getEarn().then(function (response) {
      // console.log(response.data);
      if (response.data.status === -1) {
        dispatch(setLogout());
      }
      if (response.data.status) {
        setOfferwalls(() => {
          return response.data.data.offerwalls;
        });
        setSurveys(() => {
          return response.data.data.surveys;
        });
        setOfferApi(() => {
          return response.data.data.offerApi;
        });
        // setAdgemApi(() => {
        //   return response.data.data.adgemApi;
        // });
        setOgadsApi(() => {
          return response.data.data.ogadsApi;
        });
        
        setYoumi(() => {
          return response.data.data.youmi;
        });

        setHang(() => {
          return response.data.data.HANG;
        });
        setRunOgads(() => {
          return response.data.data.run_ogads;
        });
        
        setRunYoumi(() => {
          return response.data.data.run_youmi;
        });
        
        setRunHang(() => {
          return response.data.data.run_hang;
        });
        setImgYoumi(() => {
          return response.data.data.img_youmi;
        });
        
        setImgHang(() => {
          return response.data.data.img_hang;
        });
        if (response.data.data.use_ip_used) {
          console.log("use_ip_used");
          setTimeout(() => {
            checkIp();
            console.log("check ip");
          }, response.data.data.timer * 1000);
        }
        
        if (response.data.data.user !== "") {
          if (response.data.data.user.balance !== balance) {
            dispatch(newBalanceAction(response.data.data.user.balance));
          }
        }
      }
      setLoading(false);
    });
  }, []);
  return (
    <>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStylesIFrame}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <div className="modal-header" style={{ paddingBottom: "20px" }}>
            <button
              type="button"
              onClick={closeModal}
              className="cancel--btn"
              aria-label="Close"
            >
              <img src="assets/image/cancel.png" alt="" />
            </button>
          </div>
          <iframe
            src={selectedOffer.data}
            style={{ width: "100%", height: "100%" }}
          ></iframe>
        </Modal>

        <Modal
          isOpen={modalIsOpenOgads}
          onRequestClose={closeModalOgads}
          style={customStylesIFrame}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <div className="modal-header" style={{ paddingBottom: "20px" }}>
            <button
              type="button"
              onClick={closeModalOgads}
              className="cancel--btn"
              aria-label="Close"
            >
              <img src="assets/image/cancel.png" alt="" />
            </button>
          </div>
          <div className="Modal_body ogadsApi">
            
            <section className="header">
             <h3><span id="tool-title">OGADS</span></h3>
        </section>
        <section className="sub-header">
            <p id="tool-description">Install Apps To Earn Points.</p>
        </section>
        <section className="apps">
              {ogadsApi.map((item, index) => (
                
                <div
                key={index}
                  className="app"
                  id="offer-40474"
                  data-url="#"
                  data-skip-modal='"true"'
                >
                <a
                
               
                href={item.link.replace("aff_sub5=", "aff_sub5="+userId)}
                target="_blank"
                rel="noopener"
              >  <div className="img-content">
                    <img src={item.picture} alt={item.name_short} width="75" /> 
                  </div>
                  <div className="text-content">
                    <p className="title">{item.name_short}</p>
                    <p className="desc">
                      {item.description.slice(0, 200)}
                    </p>
                  </div>
                  
                  
             <span  className="app-btn">  {(item.payout*350).toFixed(0)} coins</span>
              </a>
                 
                </div>
              ))}
              </section>
            </div>
           
        </Modal>


        <Modal
          isOpen={modalIsOpenYoumi}
          onRequestClose={closeModalYoumi}
          style={customStylesIFrame}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <div className="modal-header" style={{ paddingBottom: "20px" }}>
            <button
              type="button"
              onClick={closeModalYoumi}
              className="cancel--btn"
              aria-label="Close"
            >
              <img src="assets/image/cancel.png" alt="" />
            </button>
          </div>
          <div className="Modal_body ogadsApi">
            
            <section className="header">
             <h3><span id="tool-title">Youmi</span></h3>
        </section>
        <section className="sub-header">
            <p id="tool-description">Install Apps To Earn Points.</p>
        </section>
        <section className="apps">
              {youmi.map((item, index) => (
                
                <div
                key={index}
                  className="app"
                  id="offer-40474"
                  data-url="#"
                  data-skip-modal='"true"'
                >
                <a
                
               
                href={item.trackinglink+"&aff_sub2="+userId+"&aff_sub3="+item.name+"&aff_sub="+item.id}
                target="_blank"
                rel="noopener"
              >  <div className="img-content">
                    <img src={item.icon_url} alt={item.name} width="75" /> 
                  </div>
                  <div className="text-content">
                    <p className="title">{item.name}</p>
                    <p className="desc">
                      {item.task_description_for_user.en}
                    </p>
                  </div>
                  
                  
             <span  className="app-btn">  {(item.payout*350).toFixed(0)} coins</span>
              </a>
                 
                </div>
              ))}
              </section>
            </div>
           
        </Modal>

        <Modal
          isOpen={modalIsOpenHang}
          onRequestClose={closeModalHang}
          style={customStylesIFrame}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <div className="modal-header" style={{ paddingBottom: "20px" }}>
            <button
              type="button"
              onClick={closeModalHang}
              className="cancel--btn"
              aria-label="Close"
            >
              <img src="assets/image/cancel.png" alt="" />
            </button>
          </div>
          <div className="Modal_body ogadsApi">
            
            <section className="header">
             <h3><span id="tool-title">Hang</span></h3>
        </section>
        <section className="sub-header">
            <p id="tool-description">Install Apps To Earn Points.</p>
        </section>
        <section className="apps">
              {hang.map((item, index) => (
                
                <div
                key={index}
                  className="app"
                  id="offer-40474"
                  data-url="#"
                  data-skip-modal='"true"'
                >
                <a
                
               
                href={item.trackinglink+"&aff_sub2="+userId+"&aff_sub3="+item.name+"&aff_sub="+item.id}
                target="_blank"
                rel="noopener"
              >  <div className="img-content">
                    <img src={item.icon_url} alt={item.name} width="75" /> 
                  </div>
                  <div className="text-content">
                    <p className="title">{item.name}</p>
                    <p className="desc">
                      {item.task_description_for_user.en}
                    </p>
                  </div>
                  
                  
             <span  className="app-btn">  {(item.payout*350).toFixed(0)} coins</span>
              </a>
                 
                </div>
              ))}
              </section>
            </div>
           
        </Modal>

        <Modal
          isOpen={modalIsOpenApi}
          onRequestClose={closeModalApi}
          style={customStylesIFrameApi}
          ariaHideApp={false}
          contentLabel="Example Modal Api"
          className="offer_api_modal"
        >
          <div className="modal-header">
            <img src={selectedOfferApi.image_url} alt="" width="70" />
            <div className="modal-title h4">{selectedOfferApi.offer_name} </div>
            <div className="surveyModalCoins">
              <img
                src="assets/image/icon/logo32.png"
                className="surveyModalCoinsIcon"
              />
              <span>{financialFloat(selectedOfferApi.payout)}$</span>

              <a
                id="startOffer-15265-adgem"
                className="btn--startOffer"
                href={selectedOfferApi.offer_url}
                target="_blank"
                rel="noopener"
              >
                Start Offer
              </a>
            </div>
          </div>
          <div className="Modal_body">
            <h6
              style={{
                fontSize: "13px",
                fontWeight: "700",
                color: "#01d676",
                textTransform: "uppercase",
              }}
            >
              description
            </h6>
            <br />

            {selectedOfferApi.disclaimer}

            <br />
            <h6
              style={{
                fontSize: "13px",
                fontWeight: "700",
                color: "#01d676",
                textTransform: "uppercase",
              }}
            >
              Requirements
            </h6>
            <br />
            <div className="Requir">{selectedOfferApi.call_to_action}</div>
          </div>
        </Modal>

        <OfferApi
          offerApi={offerApi} 
          loading={loading}
          showModalApi={showModalApi}
          showModalAdgemApi={showModalAdgemApi}
        />

        <OfferWall 
          offerwalls={offerwalls}
          loading={loading}
          ogadsApi={run_ogads === "0" ? false : true}
          run_youmi={run_youmi === "0" ? false : true}
          run_hang={run_hang === "0" ? false : true}
          img_youmi={img_youmi}
          img_hang={img_hang}
          showFrame={showFrame}
          handleShowOgads={showFrameOgads}
          handleShowYoumi={showFrameYoumi}
          handleShowHang={showFrameHang}
          
          type="offer"
        />

        <OfferWall
          offerwalls={surveys}
          loading={loading}
          showFrame={showFrame}
          type="surveys"
        />
      </div>
    </>
  );
}

export default EarnPage;
