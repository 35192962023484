import React from 'react';

const Section3 = () => {
    return (
        <div className="get--started--wrp">
        <div className="container position--relative">
          <div className="task--line-bg">
            <img src="assets/image/shape/task-line-bg.png" alt="" />
          </div>

          <div className="get--started--heading">
            <h3>How to get started?</h3>
            <p>
              Earning money on Cash Baunty has been made <br />
              as simple as possible
            </p>
            <a href="#" className="btn btn--earning btn--more">
              Start earning
              <span>
                <img src="assets/image/icon/arrow-right.svg" alt="see more" />
              </span>
            </a>
          </div>

          <div className="task--wrap position--relative">
            <img
              src="assets/image/img/task-line.png"
              alt=""
              className="img--fluid task--line"
            />

            <div className="task--item task--item--1">
              <img src="assets/image/img/task.png" alt="" />
              <span>Choose a task</span>
              <p>
                Party we years to order allow asked of. <br />
                We so opinion friends me message as <br />
                delight.
              </p>
              <div className="task--1 task--num">
                <img src="assets/image/img/1.png" alt="" />
              </div>
            </div>

            <div className="task--item task--item--2">
              <img src="assets/image/img/task.png" alt="" />
              <span>Complete the task</span>
              <p>
                His defective nor convinced residence <br />
                own. Connection has put impossible <br />
                own apartments boisterous.
              </p>
              <div className="task--2 task--num">
                <img src="assets/image/img/2.png" alt="" />
              </div>
            </div>

            <div className="task--item task--item--3">
              <img src="assets/image/img/task.png" alt="" />
              <span>Receive Coins</span>
              <p>
                From they fine john he give of rich he. <br />
                They age and draw mrs like. Improving <br />
                end distrusts may instantly.
              </p>
              <div className="task--3 task--num">
                <img src="assets/image/img/3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Section3;
