import React from 'react';

const Section4 = () => {
    return (
        <div className="why--freecash">
        <div className="container">
          <div className="row why-container position--relative">
            <div className="why--freecash--shape1">
              <img src="assets/image/shape/why-bg-bottom.png" alt="" />
            </div>
            <div className="why--freecash--shape2">
              <img src="assets/image/shape/why-bg-top.png" alt="" />
            </div>
            <div className="why-fc--heading">
              <div className="why--cashout--heading">
                <h3>Why is Free Cash</h3>
                <p>
                  List of all the features that made us <br />
                  number #1
                </p>
              </div>
            </div>

            <div className="why-fc--row1">
              <div className="freecash--itm paypal--cashout text--center">
                <img
                  src="assets/image/brand/paypal.png"
                  alt=""
                  className="img--fluid"
                />
                <h5>Paypal</h5>
                <p>Instant cashouts</p>
              </div>
              <div className="freecash--itm freecash--support text--center">
                <div>
                  <img src="assets/image/icon/support1.svg" alt="" />
                  <h5>Support</h5>
                  <a href="#">
                    <span>24</span>
                    <span>/</span>
                    <span>07</span>
                  </a>
                  <p>
                    <span>24</span>
                    <span>07</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="why-fc--row2">
              <div className="freecash--itm cashout--timer">
                <div>
                  <img src="assets/image/icon/timer.svg" alt="" />
                </div>
                <div>
                  <p>
                    Earn $1.00 every 5-10 minutes <br />
                    by completing offers on Cash Baunty
                  </p>
                  <span>Earn every 5-10 minutes</span>
                </div>
              </div>
              <div className="freecash--itm cashout--starting text--center">
                <div className="cashout--icon">
                  <img src="assets/image/icon/salary.svg" alt="" />
                </div>
                <h5>
                  Cashouts starting <br />
                  at <span>$0.01</span>
                </h5>
                <img src="assets/image/icon/cashout-help.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Section4;
