import axios from "axios";
import React, { useEffect, useState } from "react"; 
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { decode, encode, encodeFunc } from "../functions.js/functions";
import { setLogin } from "../redux/actions/userActions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notify } from "react-notify-toast";
import RegisterRight from "../components/RegisterRight"; 
function RegisterPage() {
  const [nameParams] = useSearchParams();
  const refUrl = nameParams.get("ref"); // "testCode" 
  const userId = useSelector((state) => state.userReducer.userId);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [ref, setRef] = useState("");
  const [avatar, setAvatar] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const images = importAll(require.context('../avatars', true, /\.(png|jpe?g|svg)$/));
  
  useEffect(() => {
    if (userId) {
      navigate("/");
    }
      setRef(refUrl);
      var item = images[Math.floor(Math.random()*images.length)];
     
      changeAvatar(item.slice(2))
  }, []);
 

  function importAll(r) { 
    return r.keys() ;
  }
    function changeAvatar(img) { 
      setAvatar(img);
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    //   {
    //     "name": "name1112",
    //     "email":"alsa7err902@gmail.com" ,
    //     "password":"503063aa" ,
    //     "password_confirmation": "503063aa",
    //     "cc": "...",
    //     "did":1,
    //     "d":"1182x1184x1185x1260x1234x1246x1254x1237x1196x1243x1257x1258x1202x1194x1197x1212x1252x1259x1248x1257x1261x1200x1254x1267x1266",
    //     "what  d":"الوقت الان ثم الايميل مثل  '1222252|alsa7err902@gmail.com' ثم نقوم بتشفيره "

    // }
    // time now
    const time = Date.now();
    const decode1 = encodeFunc(time + "|" + email);
    // console.log(decode1);
    const article = {
      name: name,
      email: email,
      password: password,
      password_confirmation: password_confirmation,
      did: 1,
      d: decode1,
      rb: ref,
      avatar: avatar, 
      cc: "...",
    };
    const response = await axios
      .post("https://admin.cashbaunty.com/api/register", article)
      .then((response) => {
        console.log(response.data);
        response.data["data"] = JSON.parse(decode(response.data["data"]));
         if (response.data["data"]["status"] === -2) {
           setTimeout(function () {
            notify(response.data["data"]["message"]);
            navigate("/login");
          }, 3000);
          toast.success("success register ");
        } else if (response.data["data"]["status"] === 1) {
          toast.success("success register ");

          dispatch(setLogin(response.data["data"]));
          window.location.replace("/earn"); 
        } else {
          toast.error(response.data["data"]["message"]);
        }
        
    setLoading(false);
      })
      .catch((err) => {
        console.log("Err", err);
        setLoading(false);
      });
  };
  
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="register--wrap">
        <div className="container">
          <div className="row register--row signup--row justify--between">
            <div className="register--content register--left">
              <h3>Sign up</h3>
              <small>
                Start now by creating a new account <br />
                and start earning money.  
              </small> 
               <Form
                onSubmit={handleSubmit}
                className="register--form"
                name="register"
              >
                <div className="from--control">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Username"
                  />
                </div>
                {/* {
                    images.map(
                      (image, index) => <img key={index} src={'../avatars'+image.slice(1)} width="50" alt="info" />
                    )
              } */}
                <div className="from--control">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="name_123@gmail.com"
                  />
                </div>

                <div className="from--control">
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                </div>
                <div className="from--control">
                  <input
                    type="password"
                    value={password_confirmation}
                    onChange={(e) => setPassword_confirmation(e.target.value)}
                    placeholder="Confirm password"
                  />
                </div>
                <div className="from--control">
                  <input
                    type="text"
                    value={ref}
                    onChange={(e) => setRef(e.target.value)}
                    placeholder="Referral code (if any)"
                  />
                </div>
                <p className="register--p1">
                  By registering, you agree to the
                  <NavLink to="/terms_of_service">Terms of service </NavLink>
                </p>
                <button type="submit" className="submit" disabled={loading}>
                <span className={loading ? "loadingdiv spinner" : "loadingdiv"}></span>
                  <span>Sign Up</span>
                   
                </button>
              </Form>
              
              <p className="text--center">
                Already have an account? <NavLink to="/login">Sign in</NavLink>
              </p>
            </div>

            <RegisterRight />
          </div>
 
        </div>
      </div>
    </>
  );
}

export default RegisterPage;
