import React, { useEffect, useState } from "react";
import { getProfile, sendCodeAgain, updateImg, updatePassword } from "../network/userNet";
import Chart from "react-apexcharts";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { decode, getStyle, logoutNow } from "../functions.js/functions";
import { newBalanceAction, setLogin, setLogout, setUpdateImg } from "../redux/actions/userActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Lodaing from "../components/Lodaing";
import { checkIp } from "../network/earnNet";

function ProfilePage() {
  const userId = useSelector((state) => state.userReducer.userId);
  const name = useSelector(state => state.userReducer.name); 
  const img = useSelector(state => state.userReducer.avatar); 
  const balance = useSelector(state => state.userReducer.balance); 
  const [loading, setLoading] = useState(false);
  const [dataUser, setDatauser] = useState(0);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [band, setPand] = useState([]);
  const [activeTab, setActiveTab] = useState("panel1");
  const [activeTabEarn, setActiveTabEarn] = useState("earn--panel1");
  const [activeSmallTabEarn, setActiveSmallTabEarn] = useState("offer--panel1");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let days = [];
  const [daysS, setDays] = useState([]);
  let today = moment();
  const [activeTabWith, setActiveTabWith] = useState("withdrawls--panel1");
  useEffect(() => {
    var i = 0;
    for (i = 0; i < 6; i++) {
      let day = today.subtract(i, "days");
      days.push(day.format("dddd"));
    }
    setDays(days.reverse());
    getProfile().then(function (response) { 
      console.log(response.data);
      if (response.data.status === -1) { 
        dispatch(setLogout());
      } else {
         
        // dispatch(setLogin(response.data)); 
        dispatch(newBalanceAction(response.data.balance)); 
        setDatauser(() => {
          return response.data;
        });

        if(response.data.use_ip_used){  
          setTimeout(() => {
            checkIp()
          }, response.data.timer * 1000);
        }
        
      }
    });
  }, []);
  function importAll(r) { 
    return r.keys() ;
  }
  function updateAvatar (){
    const images = importAll(require.context('../avatars', true, /\.(png|jpe?g|svg)$/));
    var img2 = images[Math.floor(Math.random()*images.length)];
    if(img2.slice(2) === img){
       img2 = images[Math.floor(Math.random()*images.length)];
    }
    const data = { 
      img:img2.slice(2),
    };
    updateImg(data).then(function (response) { 
       if (response.data.status === -1) {
          
         dispatch(setLogout());
       } else {
        dispatch(setUpdateImg(response.data.message));
        
       }
     });
  }
  const reSendCode = () => {
    setLoading(true);
    sendCodeAgain().then(function (response) {
      
      response.data["data"] = JSON.parse(decode(response.data["data"]));
       
      notify(response.data.data.message);
      
    setLoading(false);
    }).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
      setLoading(false);
    });
  };

  const notify = (msg) => toast(msg);
  const updatePassword1 = () => {
    setLoading(true);
    if (newPassword === cPassword && newPassword.length >= 8) {
      const data = {
        type: 4,
        data: oldPassword + "||" + newPassword,
      };
      updatePassword(data).then(function (response) {
        
    setLoading(false);
      });
    } else {
      if (newPassword.length < 8) {
        alert("must be at least 8 characters in length");
      } else if (newPassword !== cPassword) {
        alert("Password confirmation does not match password");
      }
      setLoading(false);
    }
  };
  if (!userId) {
    navigate("/login");
  }
  const changeBigTab = (tab) => {
    setActiveTab(tab);
  };

  const changeEarnTab = (tab) => {
    setActiveTabEarn(tab);
  };

  const changeEarnTabSmall = (tab) => {
    setActiveSmallTabEarn(tab);
  };

  const changeWithTab = (tab) => {
    setActiveTabWith(tab);
  };
  const seriesEarn = [
    //data on the y-axis
    {
      name: "earn",
      data: dataUser.sumEarnDay ,
    },
  ];
  const seriesOffer = [
    //data on the y-axis
    {
      name: "Offers",
      data: dataUser.countOfferDay ,
    },
  ];

  const optionsOffer = {
    //data on the x-axis
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    title: {
      text: "complate offer by week",
      align: "left",
      style: {
        color: "#fff",
      },
    },
    colors: ["#f57059"],
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: daysS,
      labels: {
        show: true,
        style: {
          colors: "#fff",
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "#fff",
          fontSize: "12px",
        },
      },
    },
  };
  const optionsEarn = {
    //data on the x-axis
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    title: {
      text: "earn by week",
      align: "left",
      style: {
        color: "#fff",
      },
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: daysS,
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "#fff",
          fontSize: "12px",
        },
      },
    },
  };
  return (
    <>
      {dataUser ? (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {dataUser.verified ?<></> :(
          <div className="div_info" >
            note :Your account is not verified. Check your email and confirm
            your account.
            <button
              id="resend_code_email"
              className="btn_do"
              type="submit"
              onClick={reSendCode}
              style={{ marginLeft: "10px", color: "#fff" }}
              disabled={loading}
            >
              <span className={loading ? "loadingdiv spinner" : "loadingdiv"}></span>
                  <span>Resend code</span> 
            </button>
          </div>) }

          
          {dataUser.band  ?  <div className="div_danger">
          Your account is banned because: <br />
           {dataUser.band.reason }
          </div>
          :  ""}
          <div className="profile--settings--wrap position--relative">
            <div className="profile--ettings--shape1">
              <img src="assets/image/shape/header-bottom.png" alt="" />
            </div>

            <div className="container">
              <div className="row justify--between profile--general--row">
                <div className="general--statistics">
                  <div className="statistics--header">
                    <div className="statistics--user">
                      <div className="statistics--user--img">
                      <div className="account--img">
                                <img src={'../avatars/'+img} alt="" />
                                </div>
                      
                      </div>
                      <div className="statistics--user--name">{name}</div>
                    </div>
                    <div className="required--xp">
                      194,240 XP Earned. 60 XP required for next level up.
                    </div>
                    <div className="coinBar">
                      <div className="coinBarContainer profile--coinBar--Container">
                        <div className="coinBarValue value--num"></div>
                      </div>
                    </div>
                  </div>

                  <div className="statistics--summery profile--statistics--summery">
                    <div className="statistics--sum--itm profile--statistics-sum--itm">
                      <div className="stat--sum--icon st--sum--icon1">
                        <img
                          src="assets/image/icon/successful-update.png"
                          alt=""
                        />
                      </div>
                      <div className="statistics--sum--info">
                        <p>Completed offers</p>
                        <h6>{dataUser.complatedOffers}</h6>
                      </div>
                    </div>

                    <div className="statistics--sum--itm profile--statistics-sum--itm">
                      <div className="stat--sum--icon st--sum--icon2">
                        <img src="assets/image/icon/coins.png" alt="" />
                      </div>
                      <div className="statistics--sum--info">
                        <p>Coins earned</p>
                        <h6>{dataUser.coinsEarned}</h6>
                      </div>
                    </div>

                    <div className="statistics--sum--itm profile--statistics-sum--itm">
                      <div className="stat--sum--icon st--sum--icon3">
                        <img
                          src="assets/image/icon/successful-update.png"
                          alt=""
                        />
                      </div>
                      <div className="statistics--sum--info">
                        <p>Users referred</p>
                        <h6>{dataUser.countReferred}</h6>
                      </div>
                    </div>
                  </div>

                  <div className="statistics--chat">
                    <h5>Recent earnings (7 Days)</h5>
                    <small>Coins for week</small>

                    <div>
                      <Chart
                        options={optionsEarn}
                        series={seriesEarn}
                        type="line"
                        height="200"
                      />
                      <Chart
                        options={optionsOffer}
                        series={seriesOffer}
                        type="line"
                        height="200"
                      />
                    </div>
                  </div>
                </div>
                <div className="general--info--wrap">
                  <h3>General</h3>
                  <ul className="general--info--list">
                    <li>
                      <span> Cash Baunty ID</span>
                      <p> {dataUser.uid} </p>
                    </li>
                    <li>
                      <span>Balance</span>
                      <p>{dataUser.balance} Coins</p>
                    </li>
                    <li>
                      <span>Verified</span>
                      <p>{dataUser.verified ? "Yes" : "No"}</p>
                    </li>
                    {/* <li>
                            <span>Max offer value</span>
                            <p>4,000 coins</p>
                        </li> */}
                    <li>
                      <span>Referrer</span>
                      <p> {dataUser.countReferred}</p>
                    </li>
                    <li>
                      <span>Referral Earnings</span>
                      <p>{dataUser.referralEarnings} coins</p>
                    </li>
                    <li>
                      <span>Date Joined</span>
                      <p>{dataUser.date}</p>
                    </li>
                    <li>
                      <span>Email</span>
                      <p> {dataUser.email}</p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="profile--settings--panel">
                <div className="profile--tabed--panel">
                  <ul className="profile--tabs">
                    <li>
                      <a
                        href="#panel1"
                        onClick={() => changeBigTab("panel1")}
                        className={activeTab === "panel1" ? "active" : ""}
                        style={{ color: "#fff" }}
                      >
                        Settings
                      </a>
                    </li>
                    <li>
                      <a
                        href="#panel2"
                        onClick={() => changeBigTab("panel2")}
                        className={activeTab === "panel2" ? "active" : ""}
                        style={{ color: "#fff" }}
                      >
                        Earnings
                      </a>
                    </li>
                    <li>
                      <a
                        href="#panel3"
                        onClick={() => changeBigTab("panel3")}
                        className={activeTab === "panel3" ? "active" : ""}
                        style={{ color: "#fff" }}
                      >
                        Withdrawal
                      </a>
                    </li>
                  </ul>
                  <div className="profile--tabContainer">
                    <div
                      id="panel1"
                      className="profile--tab--panel"
                      style={{ display: activeTab === "panel1" ? "" : "none" }}
                    >
                      <h3>Settings</h3>
                      <div className="profile--edits--area">
                        <div className="profile--edit--img">
                        
                          <img
                            src={'../avatars/'+img}
                            className="profile--img"
                            alt=""
                          />
                          <div className="camera--icon">
                            <img onClick={updateAvatar} src="assets/image/icon/camera.png" alt="" />
                          </div>
                        </div>
                        <p>{name}</p>
                      </div>
                      <div className="profile--form--row d--flex align--center justify--between">
                        <h4 className="change--pass--heading">
                          Change password
                          <img src="assets/image/icon/play-down.png" alt="" />
                        </h4>
                      </div>

                      <div className="profile--form--row d--flex align--center justify--between">
                        <div className="profile--form--item">
                          <label htmlFor="">Current password</label>
                          <div className="profile--form--password">
                            <input
                              value={oldPassword}
                              onChange={(e) => setOldPassword(e.target.value)}
                              type="password"
                              placeholder="Current password"
                            />
                            <div className="eye--pass--show">
                              <img
                                src="assets/image/icon/eye-solid.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div className="profile--form--item">
                          <label htmlFor="">New password</label>
                          <div className="profile--form--password">
                            <input
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                              type="password"
                              placeholder="New password"
                            />
                            <div className="eye--pass--show">
                              <img
                                src="assets/image/icon/eye-solid.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="profile--form--row d--flex align--center justify--between">
                        <div className="profile--form--item">
                          <label htmlFor="">Confirm the new password</label>
                          <div className="profile--form--password">
                            <input
                              value={cPassword}
                              onChange={(e) => setCPassword(e.target.value)}
                              type="password"
                              placeholder="Confirm the new password"
                            />
                            <div className="eye--pass--show">
                              <img
                                src="assets/image/icon/eye-solid.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="profile--form--row d--flex align--center justify--between">
                        <button
                          onClick={updatePassword1}
                          disabled={loading}
                          className="btn btn--more btn--seemore profile--btn--save"
                         
                        >Save
                           <span className={loading ? "loadingdiv spinner" : "loadingdiv"}></span>
                           
                          
                          <span>
                            <img
                              src="assets/image/icon/arrow-right.svg"
                              alt="see more"
                            />
                          </span>
                        </button>
                      </div>
                    </div>

                    <div
                      id="panel2"
                      className="profile--tab--panel"
                      style={{ display: activeTab === "panel2" ? "" : "none" }}
                    >
                      <h3>Earnings</h3>
                      <ul className="profile--earning--tabs">
                        <li>
                          <a
                            href="#earn--panel1"
                            onClick={() => changeEarnTab("earn--panel1")}
                            className={
                              activeTabEarn === "earn--panel1" ? "active" : ""
                            }
                            style={{ color: "#fff" }}
                          >
                            Earnings
                          </a>
                        </li>
                        <li>
                          <a
                            href="#earn--panel2"
                            onClick={() => changeEarnTab("earn--panel2")}
                            className={
                              activeTabEarn === "earn--panel2" ? "active" : ""
                            }
                            style={{ color: "#fff" }}
                          >
                            Pending offers
                          </a>
                        </li>
                        <li>
                          <a
                            href="#earn--panel3"
                            onClick={() => changeEarnTab("earn--panel3")}
                            className={
                              activeTabEarn === "earn--panel3" ? "active" : ""
                            }
                            style={{ color: "#fff" }}
                          >
                            Chargebacks{" "}
                          </a>
                        </li>
                      </ul>

                      <div
                        id="earn--panel1"
                        className="earning--tab--panel"
                        style={{
                          display:
                            activeTabEarn === "earn--panel1" ? "" : "none",
                        }}
                      >
                        <ul className="offers--earning--tabs">
                          <li>
                            <a
                              href="#offer--panel1"
                              onClick={() =>
                                changeEarnTabSmall("offer--panel1")
                              }
                              className={
                                activeSmallTabEarn === "offer--panel1"
                                  ? "active"
                                  : ""
                              }
                              style={{ color: "#fff" }}
                            >
                              Offers
                              <div className="offers--rounded"></div>
                            </a>
                          </li>

                          <li>
                            <a
                              href="#offer--panel2"
                              onClick={() =>
                                changeEarnTabSmall("offer--panel2")
                              }
                              className={
                                activeSmallTabEarn === "offer--panel2"
                                  ? "active"
                                  : ""
                              }
                              style={{ color: "#fff" }}
                            >
                              Leaderboard
                              <div className="offers--rounded"></div>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#offer--panel3"
                              onClick={() =>
                                changeEarnTabSmall("offer--panel3")
                              }
                              className={
                                activeSmallTabEarn === "offer--panel3"
                                  ? "active"
                                  : ""
                              }
                              style={{ color: "#fff" }}
                            >
                              Videos
                              <div className="offers--rounded"></div>
                            </a>
                          </li>
                        </ul>

                        <div
                          id="offer--panel1"
                          className="offers--tabs--panel"
                          style={{
                            display:
                              activeSmallTabEarn === "offer--panel1"
                                ? ""
                                : "none",
                          }}
                        >
                          <div className="offers--show--wrap">
                            <div className="offer--show--heading">
                              <h5>Offers</h5>
                              <div className="offer--selectbox--wrap">
                                <label>Offer wall</label>
                                <select className="offer--selectbox">
                                  <option data-display="All">All</option>
                                  <option value="1">AdGate Rewards</option>
                                  <option value="2">Ayet Studios</option>
                                  <option value="4">TheoremReach</option>
                                </select>
                              </div>
                            </div>

                            <div className="offer--show--content">
                              <table className="table leaderboard--table kt-datatable">
                                <thead>
                                  <tr>
                                    <th>offer id</th>
                                    <th>offer name</th>
                                    <th>Coins</th>
                                    <th>Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {dataUser.histComplateOffer.map(
                                    (item, index) => (
                                      <tr key={index}>
                                        <td>{item.offerid}</td>
                                        <td>{item.offName}</td>
                                        <td>{item.points}</td>
                                        <td>{item.created_at}</td>
                                        <td></td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div
                          id="offer--panel2"
                          className="offers--tabs--panel"
                          style={{
                            display:
                              activeSmallTabEarn === "offer--panel2"
                                ? ""
                                : "none",
                          }}
                        >
                          <div className="offer--show--heading">
                            <h5>Leaderboard</h5>
                            <div className="offer--selectbox--wrap">
                              <label>Offer wall</label>
                              <select className="offer--selectbox">
                                <option data-display="All">All</option>
                                <option value="1">AdGate Rewards</option>
                                <option value="2">Ayet Studios</option>
                                <option value="4">TheoremReach</option>
                              </select>
                            </div>
                          </div>

                          <div className="offer--show--content">
                            <p>Nothing to show</p>
                          </div>
                        </div>

                        <div
                          id="offer--panel3"
                          className="offers--tabs--panel"
                          style={{
                            display:
                              activeSmallTabEarn === "offer--panel3"
                                ? ""
                                : "none",
                          }}
                        >
                          <div className="offer--show--heading">
                            <h5>Videos</h5>
                            <div className="offer--selectbox--wrap">
                              <label>Offer wall</label>
                              <select className="offer--selectbox">
                                <option data-display="All">All</option>
                                <option value="1">AdGate Rewards</option>
                                <option value="2">Ayet Studios</option>
                                <option value="4">TheoremReach</option>
                              </select>
                            </div>
                          </div>

                          <div className="offer--show--content">
                            <p>Nothing to show</p>
                          </div>
                        </div>
                      </div>

                      <div
                        id="earn--panel2"
                        className="earning--tab--panel"
                        style={{
                          display:
                            activeTabEarn === "earn--panel2" ? "" : "none",
                        }}
                      >
                        Hello2
                      </div>

                      <div
                        id="earn--panel3"
                        className="earning--tab--panel"
                        style={{
                          display:
                            activeTabEarn === "earn--panel3" ? "" : "none",
                        }}
                      >
                        Hello3
                      </div>
                    </div>

                    <div
                      id="panel3"
                      className="profile--tab--panel"
                      style={{ display: activeTab === "panel3" ? "" : "none" }}
                    >
                      <ul className="withdrawls--earning--tabs">
                        <li>
                          <a
                            href="#withdrawls--panel1"
                            onClick={() => changeWithTab("withdrawls--panel1")}
                            className={
                              activeTabWith === "withdrawls--panel1"
                                ? "active"
                                : ""
                            }
                            style={{ color: "#fff" }}
                          >
                            Completed{" "}
                          </a>
                        </li>
                        <li>
                          <a
                            href="#withdrawls--panel2"
                            onClick={() => changeWithTab("withdrawls--panel2")}
                            className={
                              activeTabWith === "withdrawls--panel2"
                                ? "active"
                                : ""
                            }
                            style={{ color: "#fff" }}
                          >
                            Pending{" "}
                          </a>
                        </li>
                        
                        <li>
                          <a
                            href="#withdrawls--panel3"
                            onClick={() => changeWithTab("withdrawls--panel3")}
                            className={
                              activeTabWith === "withdrawls--panel3"
                                ? "active"
                                : ""
                            }
                            style={{ color: "#fff" }}
                          >
                            Rejected
                          </a>
                        </li>
                      </ul>
                      <div
                        id="withdrawls--panel1"
                        className="withdrawls--tab--panel"
                        style={{
                          display:
                            activeTabWith === "withdrawls--panel1"
                              ? ""
                              : "none",
                        }}
                      >
                        <div className="withdrawls--show--panel">
                          <h4>Completed Withdrawals</h4>

                          <table className="table leaderboard--table kt-datatable">
                            <thead>
                              <tr>
                                <th title="Field #1"> ID</th>
                                <th title="Field #2">method</th>
                                <th title="Field #3">to</th>
                                <th title="Field #9">date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataUser.withdrawalComplated.map(
                                (item, index) => (
                                  <tr key={index} data-expanded="true">
                                    <td>{item.id}</td>
                                    <td>{item.g_name}</td>
                                    <td>{item.to_acc}</td>
                                    <td>{item.created_at}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        id="withdrawls--panel2"
                        className="withdrawls--tab--panel"
                        style={{
                          display:
                            activeTabWith === "withdrawls--panel2"
                              ? ""
                              : "none",
                        }}
                      >
                        <div className="withdrawls--show--panel">
                          <h4>Pending Withdrawals</h4>
                          <table className="table leaderboard--table kt-datatable">
                            <thead>
                              <tr>
                                <th title="Field #1"> ID</th>
                                <th title="Field #2">method</th>
                                <th title="Field #3">to</th>
                                <th title="Field #9">date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataUser.withdrawalPending.map((item, index) => (
                                <tr key={index} data-expanded="true">
                                  <td>{item.id}</td>
                                  <td>{item.g_name}</td>
                                  <td>{item.to_acc}</td>
                                  <td>{item.created_at}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div
                        id="withdrawls--panel3"
                        className="withdrawls--tab--panel3"
                        style={{
                          display:
                            activeTabWith === "withdrawls--panel3"
                              ? ""
                              : "none",
                        }}
                      >
                        <div className="withdrawls--show--panel">
                          <h4>Rejected Withdrawals</h4>
                          <table className="table leaderboard--table kt-datatable">
                            <thead>
                              <tr>
                                <th title="Field #1"> ID</th>
                                <th title="Field #2">method</th>
                                <th title="Field #3">to</th>
                                <th title="Field #9">reason</th>
                                <th title="Field #9">date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataUser.withdrawalRejected.map((item, index) => (
                                <tr key={index} data-expanded="true">
                                  <td>{item.id}</td>
                                  <td>{item.g_name}</td>
                                  <td>{item.to_acc}</td>
                                  <td>{item.message}</td>
                                  <td>{item.created_at}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        // <div style={{ height: "100vh" }}>
        //   <div className="load"></div>
        // </div>
        <Lodaing />
      )}
    </>
  );
}

export default ProfilePage;
